@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

.container {
  padding: 0 1.5rem;
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  display: none;
}

input[type='radio']:before,
input[type='radio']:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
input[type='radio']:checked:before {
  background: #ffffff;
}
input[type='radio']:checked:after {
  background: #0080bc;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 5px;
  left: 5px;
}

input[type='radio']:checked + label > p {
  color: #ffffff;
}

input[type='tel']:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
