.custom-styles {
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
}

.custom-styles .ReactInputVerificationCode__container {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  max-width: 100%;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #4b5563;
  font-weight: inherit;
  border: 2px solid #dde2e5;
  font-size: 14px;
  font-weight: 500;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.custom-styles .ReactInputVerificationCode__item:after {
  padding: 0;
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  border: 2px solid #dde2e5;
  color: #4b5563;
  outline: 0;
  transition: box-shadow 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: #fdbe57;
}
